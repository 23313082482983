import Header from "./components/Header";
import Tasks from "./components/Tasks";
import AddTask from "./components/AddTask";

import { useState } from 'react';



function App() {


  const [showAddTask, setShowAddTask] = useState(false)
  const [tasks, setTasks] = useState([
    {
      id: 1,
      text: 'doctors app',
      day: 'feb 5th at 2:30pm',
      reminder: true
    },
    {
      id: 2,
      text: 'School Meeting',
      day: 'feb 5th at 1:30pm',
      reminder: false
    },
    {
      id: 3,
      text: 'doctors app2',
      day: 'feb 5th at 3:30pm',
      reminder: true
    }
  ])

  // Delete task
  const deleteTask = (id) => {
    setTasks(tasks.filter((task) => task.id !== id))

  }

  //Toggle reminder
  const toggleReminder = (id) => {
    setTasks(tasks.map((task) => task.id === id ? {...task, reminder: !task.reminder} : task))
  } 

  const addTask = (task) => {
    const id = Math.floor(Math.random() * 10000 )+ 1
    const newtask = {id, ...task}
    setTasks([...tasks, newtask])


  }


  return (
    <div className="container">
        <Header onAdd={() => setShowAddTask(!showAddTask)} showAdd={showAddTask}/>
        { showAddTask && <AddTask onAdd={addTask}/>}
        {tasks.length > 0 ? <Tasks tasks={tasks}  onDelete={deleteTask} onToggle={toggleReminder} /> : 'No tasks to show '}
    </div>
  );
}

export default App;
